/* Inkluder Diatype Light fonten */
@font-face {
  font-family: "Diatype Light";
  src:
    url("./fonts/ABCDiatype-Light.otf") format("woff2"),
    /* oppdater denne URL-en med riktig sti til fonten din */
      url("./fonts/ABCDiatype-Light.otf") format("woff"); /* oppdater denne URL-en med riktig sti til fonten din */
  font-weight: normal;
  font-style: normal;
}

/* Global font-styling */
body {
  font-family: "Diatype Light", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.logo-scroll {
  display: flex;
  width: max-content;
  animation: scroll 60s linear infinite;
}

.logo-scroll img {
  margin-right: 60px; /* Adjust this value to increase/decrease margin */
}

.overflow-hidden {
  white-space: nowrap;
}

.corner-marker-green {
  position: fixed;
  width: 20px;
  height: 20px;
  background-color: #00ff00;
  opacity: 0.7;
  z-index: 9999;
}

.corner-marker-yellow {
  position: fixed;
  width: 20px;
  height: 20px;
  background-color: #ffff00;
  opacity: 0.7;
  z-index: 9999;
}

.top-left {
  top: 0;
  left: 0;
}
.top-right {
  top: 0;
  right: 0;
}
.bottom-left {
  bottom: 0;
  left: 0;
}
.bottom-right {
  bottom: 0;
  right: 0;
}

.project-id {
  position: fixed;
  top: 0;
  left: 0;
  color: red;
  font-weight: bold;
  z-index: 10000; /* Increased z-index to be above the corner marker */
  font-size: 12px;
  font-family: monospace;
  padding: 4px; /* Added padding instead of top/left offset */
}
